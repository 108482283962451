import apiService from '@/services/apiService'
import { useWorkspaceStore } from '@/stores/workspacestore'

export const ranalysis = {
  props: {
    analysisObj: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      pngs: [],
      graphicals: [],
      basicTables: [],
      summaryTables: [],
      basicTests: [],
      crossTables: [],
      postHocTests: [],
      dataViews: [],
      venns: [],
      upsetJSs: [],
      generalInfos: [],
      resultsLoaded: false
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    return { workspaceStore }
  },
  mounted () {
    if (!this.workspaceStore.hasDataset) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.workspaceStore.getWorkflow()
        .then(() => {
          loadingComponent.close()
        })
        .catch(() => {
          loadingComponent.close()
        })
    }
    if (this.analysisObj !== null) {
      this.initFormAndResults()
    }
  },
  computed: {
    excludeResults: function () {
      return []
    },
    hasResults: function () {
      let hasResults = true
      if (this.pngs.length === 0 && this.graphicals.length === 0 &&
        this.basicTables.length === 0 && this.summaryTables.length === 0 &&
        this.basicTests.length === 0 && this.crossTables.length === 0 &&
        this.postHocTests.length === 0 && this.dataViews.length === 0 &&
        this.venns.length === 0 && this.upsetJSs.length === 0 &&
        this.generalInfos.length === 0) {
        hasResults = false
      }
      return hasResults
    },
    png: function () {
      return this.pngs[0]
    },
    graphical: function () {
      return this.graphicals[0]
    },
    basicTable: function () {
      return this.basicTables[0]
    },
    summaryTable: function () {
      return this.summaryTables[0]
    },
    basicTest: function () {
      return this.basicTests[0]
    },
    crossTable: function () {
      return this.crossTables[0]
    },
    postHocTest: function () {
      return this.postHocTests[0]
    },
    dataView: function () {
      return this.dataViews[0]
    },
    venn: function () {
      return this.venns[0]
    },
    upsetJS: function () {
      return this.upsetJSs[0]
    },
    generalInfo: function () {
      return this.generalInfos[0]
    }
  },
  methods: {
    beforeRun: function (data) {},
    afterRun: function (data) {},
    reset: function (data) {
      this.pngs = []
      this.graphicals = []
      this.basicTables = []
      this.summaryTables = []
      this.basicTests = []
      this.crossTables = []
      this.postHocTests = []
      this.dataViews = []
      this.venns = []
      this.upsetJSs = []
      this.generalInfos = []
      this.resultsLoaded = false
    },
    setResults: function (data) {
      for (let key of Object.keys(data)) {
        if (!this.excludeResults.includes(key)) {
          let jresult = data[key]
          if (jresult.type === 'png') {
            this.pngs.push(jresult)
          } else if (jresult.type === 'PlotLy') {
            this.graphicals.push(jresult)
          } else if (jresult.type === 'BasicTable') {
            this.basicTables.push(jresult)
          } else if (jresult.type === 'SummaryTable') {
            this.summaryTables.push(jresult)
          } else if (jresult.type === 'BasicTest') {
            this.basicTests.push(jresult)
          } else if (jresult.type === 'CrossTable') {
            this.crossTables.push(jresult)
          } else if (jresult.type === 'PostHocTests') {
            this.postHocTests.push(jresult)
          } else if (jresult.type === 'DataView') {
            this.dataViews.push(jresult)
          } else if (jresult.type === 'UpsetJS') {
            this.upsetJSs.push(jresult)
          } else if (jresult.type === 'Venn') {
            this.venns.push(jresult)
          } else if (jresult.type === 'GeneralInfo') {
            this.generalInfos.push(jresult)
          }
        }
      }
      this.resultsLoaded = true
    },
    run: function () {
      this.reset()
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.workspaceStore.runRFunction(this.params)
        .then(data => {
          this.beforeRun(data)
          this.setResults(data)
          this.afterRun(data)
          loadingComponent.close()
        })
        .catch(function () {
          // handle in api.js
          loadingComponent.close()
        }
        )
    },
    initFormAndResults: function () {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      for (const key in this.analysisObj.meta.func_args) {
        this[key] = this.analysisObj.meta.func_args[key]
      }
      apiService.runRFunction({
        'func_name': 'r_get_analysis',
        'analysisName': this.analysisObj.object_name
      })
        .then(data => {
          this.beforeRun(data)
          this.setResults(data)
          this.afterRun(data)
          loadingComponent.close()
        })
        .catch(() => {
          loadingComponent.close()
        })
    }
  }
}
