<template>
  <div class="columns">
    <div class="column is-one-third">
      <card-component
        title="1. Display individuals on components"
        class="tile is-child"
        :displayHelp="helpname"
      >
        <validation-observer v-slot="{ handleSubmit }" ref="r_plotind_observer">
          <validation-provider
            v-slot="{ errors, valid}"
            :rules= {required:true,integer:true,min_value:1,max_value:nbCP,excluded:axisy}
            :customMessages = "{excluded: 'The 2 axes must be different. Choose another component.',
            max_value: 'Only '+nbCP+' components have been computed.'}"
            name="Component to display on X (horizontal)"
          >
            <b-field
              label="Component to display on X (horizontal)"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-input
                v-model="axisx"
                expanded
                @focus="$event.target.select()"
              ></b-input>
            </b-field>
          </validation-provider>
          <validation-provider
            v-slot="{ errors, valid}"
            :rules= {required:true,integer:true,min_value:1,max_value:nbCP,excluded:axisx}
            :customMessages = "{excluded: 'The 2 axes must be different. Choose another component.',
            max_value: 'Only '+nbCP+' components have been computed.'}"
            name="Component to display on Y (vertical)"
          >
            <b-field
              label="Component to display on Y (vertical)"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-input
                v-model="axisy"
                expanded
                @focus="$event.target.select()"
              ></b-input>
            </b-field>
          </validation-provider>
          <hr/>
          <validation-provider
            v-slot="{ errors }"
            :rules= {oneOf:getAvailableVariables(datasetcolor)}
            :customMessages = "{oneOf: 'This value is not a valid variable name.'}"
            name="color"
            >
            <dataset-select
              label="Color"
              :dataset.sync="datasetcolor"
              :varname.sync="varcolor"
              v-model="varcolor"
              :message="errors"
              ref="colorSelector"
              :type="{ 'is-danger': errors[0] }"
              @select="option => selectColor(option, 'varcolor')"
              icon="color"
              :minNumericVariable="0"/>
          </validation-provider>
          <br />
          <validation-provider
            v-slot="{ errors }"
            :rules= {oneOf:getAvailableVariables(datasetshape)}
            :customMessages = "{oneOf: 'This value is not a valid variable name.'}"
            name="shape"
          >
            <dataset-select
              label="Shape"
              :dataset.sync="datasetshape"
              :varname.sync="varshape"
              v-model="varshape"
              :message="errors"
              :type="{ 'is-danger': errors[0] }"
              @select="option => selectVarname(option, 'varshape')"
              :vartype="'quali'"
              :uniquevals="6"
              :minlevel="2"
              :minCatVariable="1"
              labeltooltip="Categorical variables having between 2 and 6 distinct levels"
              icon="shape"/>
          </validation-provider>
          <br />
          <validation-provider
            v-slot="{ errors }"
            :rules= {oneOf:getAvailableVariables(datasetsize)}
            :customMessages = "{oneOf: 'This value is not a valid variable name.'}"
            name="size"
          >
            <dataset-select
              label="Size"
              :dataset.sync="datasetsize"
              v-model="varsize"
              :varname.sync="varsize"
              :message="errors"
              :type="{ 'is-danger': errors[0] }"
              @select="option => selectVarname(option, 'varsize')"
              :vartype="'quanti'"
              :minlevel="2"
              icon="size"
              labeltooltip="Non-constant quantitative variables"
              :minNumericVariable="1"/>
          </validation-provider>
          <hr v-if="availablePartialIndGroup.length > 0 || availableIndividuals.length > 0" />
          <b-field
            v-if="availablePartialIndGroup.length > 0"
            label="Partial individual group"
          >
            <b-select
              v-model="partialIndGroup"
              @input="partialIndGroupChanged()"
              placeholder="Partial individual group"
              expanded
            >
              <option
                v-for="(group,index) in inAvailablePartialIndGroup"
                :key="index"
              >
                {{ group }}
              </option>
            </b-select>
          </b-field>
          <b-field
            v-if="availableIndividuals.length > 0"
            label="Partial individuals"
          >
            <b-taginput
              v-model="partialInd"
              :data="availableIndividuals"
              autocomplete
              rounded
              @input="partialIndChanged()"
              :open-on-focus="true"
              :allow-new="false"
              icon="caret-right"
              placeholder="Add an individual"
            ></b-taginput>
          </b-field>
          <hr />
          <div class="has-text-centered">
            <b-button rounded
              class="is-primary button-shadow"
              @click.prevent="handleSubmit(run)"
            >
              <span class="icon is-small"><i class="fa fa-cogs"></i></span>
              <span> Plot individuals </span>
            </b-button>
          </div>
        </validation-observer>
      </card-component>
    </div>
    <div class="column">
      <card-component
        :title="titleResult">
      </card-component>
      <error :type='"notifications"' />
      <card-no-result v-if="!resultsLoaded">
      </card-no-result>
      <card-component v-else-if="hasResults">
        <plotly
          v-if="graphicals.length===1"
          :data="graphicals[0]"
        ></plotly>
        <plotly-list
          v-else-if="graphicals.length>1"
          :data="graphicals"
        ></plotly-list>
        <nav class="level">
          <div class="level-left" />
          <div class="buttons level-right">
            <r-save-for-report
              label="Add to report"
              :objectName="datasetName"
              :params="params"
            />
          </div>
        </nav>
      </card-component>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { ranalysis } from '@/mixins/ranalysis'
import { useWorkspaceStore } from '@/stores/workspacestore'
import { useUtilsStore } from '@/stores/utils'

export default {
  name: 'RPlotind',
  props: {
    analysisType: {
      type: String,
      default: ''
    },
    selectedDataset: {
      type: String,
      default: null
    },
    datasetName: {
      type: String,
      default: ''
    },
    nbCP: {
      type: Number,
      default: 0
    },
    availablePartialIndGroup: {
      type: Array,
      default: function () {
        return []
      }
    },
    availableIndividuals: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  components: {
    'card-component': () => import('@/components/ui/CardComponent.vue'),
    'card-no-result': () => import('@/components/ui/CardNoResult.vue'),
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider,
    'dataset-select': () => import('@/components/ui/DatasetSelect.vue'),
    'plotly-list': () => import('@/components/ui/PlotlyList.vue'),
    'plotly': () => import('@/components/rresults/Plotly.vue'),
    'r-save-for-report': () => import('@/components/RSaveForReport.vue'),
    'error': () => import('@/components/ui/Error.vue')
  },
  mixins: [ranalysis],
  data () {
    return {
      axisx: 1,
      axisy: 2,
      datasetcolor: null,
      datasetshape: null,
      datasetsize: null,
      varcolor: '',
      varshape: '',
      varsize: '',
      partialIndGroup: null,
      partialInd: []
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    const utilsStore = useUtilsStore()
    return { workspaceStore, utilsStore }
  },
  computed: {
    analyses: function () {
      return this.workspaceStore.analyses
    },
    allVarnames: function () {
      return this.utilsStore.allVarnames
    },
    titleResult: function () {
      if (this.selectedDataset) {
        return '2. Plot individuals of ' + this.username + ' on components'
      } else {
        return '2. Plot individuals on components'
      }
    },
    inAvailablePartialIndGroup: function () {
      return ['None'].concat(this.availablePartialIndGroup)
    },
    params: function () {
      let funcName = 'r_plotind'
      if (this.analysisType.startsWith('pls')) {
        funcName = 'r_plotplsind'
      }
      let params = {
        'func_name': funcName,
        'datasetName': this.datasetName,
        'axis1': parseInt(this.axisx),
        'axis2': parseInt(this.axisy)
      }
      if (this.datasetcolor !== null && this.varcolor !== '') {
        params['datasetcolor'] = this.datasetcolor
        params['varcolor'] = this.varcolor
      }
      if (this.datasetshape !== null && this.varshape !== '') {
        params['datasetshape'] = this.datasetshape
        params['varshape'] = this.varshape
      }
      if (this.datasetsize !== null && this.varsize !== '') {
        params['datasetsize'] = this.datasetsize
        params['varsize'] = this.varsize
      }
      if (this.partialIndGroup !== null && this.partialIndGroup !== 'None') {
        params['partialIndGroup'] = this.partialIndGroup
      }
      if (this.partialInd.length > 0) {
        params['partialInd'] = this.partialInd
      }
      return params
    },
    username: function () {
      let tmp = this.workspaceStore.datasets.filter(obj => obj.object_name === this.selectedDataset)
      return tmp[0].user_name
    },
    helpname: function () {
      if (this.$route.name === 'mfa' || this.analysisType === 'mfa') {
        return 'RPlotindMFA'
      } else if (this.$route.name === 'pls' || this.analysisType === 'pls') {
        return 'RPlotindPLS'
      } else if (this.$route.name === 'plsda' || this.analysisType === 'plsda') {
        return 'RPlotindPLSDA'
      } else {
        return this.$options.name
      }
    }
  },
  methods: {
    partialIndGroupChanged () {
      this.partialInd = []
      this.varcolor = ''
      this.datasetcolor = null
      this.$refs.colorSelector.reset()
    },
    partialIndChanged () {
      this.partialIndGroup = null
      this.varcolor = ''
      this.datasetcolor = null
      this.$refs.colorSelector.reset()
    },
    getAvailableVariables: function (dataset) {
      let variables = []
      if (dataset in this.allVarnames) {
        variables = this.allVarnames[dataset].map(obj => obj.name)
      }
      return variables
    },
    selectVarname: function (value, varname) {
      this[varname] = value.name
    },
    selectColor: function (value, varname) {
      this.partialInd = []
      this.partialIndGroup = null
      this.selectVarname(value, varname)
    }
  }
}
</script>
