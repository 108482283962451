import { defineStore } from 'pinia'
import apiService from '@/services/apiService'
export const useUtilsStore = defineStore('utils', {
  state: () => {
    return {
      allVarnames: {}
    }
  },
  actions: {
    reset () {
      this.$reset()
    },
    getVarnames (dataset) {
      return apiService.runRFunction({
        'func_name': 'r_column_metadata',
        'dataset': dataset
      })
        .then(data => {
          this.allVarnames[dataset] = data.data.map(obj => ({
            'name': obj.var1,
            'numeric': obj.var2,
            'uniquevals': obj.var3,
            'alldistinct': obj.var4
          }))
        })
    }
  }
})
